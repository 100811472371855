import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal routing
import styles from './Header.Module.css'; // Import the CSS Module

const Header = () => {
  // State for toggling the navbar
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Function to toggle navbar collapse
  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Close the navbar when a link is clicked
  const handleLinkClick = () => {
    setIsNavOpen(false);
  };

  return (
    <header className="navigation">
      {/* Header Top */}
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            {/* Social Links */}
            <div className="col-lg-2 col-md-4">
              <div className="header-top-socials text-center text-lg-left text-md-left">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="ti-facebook"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/primesoftsolutions/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="ti-linkedin"></i>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <i className="ti-github"></i>
                </a>
              </div>
            </div>
            {/* Contact Info */}
            <div className="col-lg-10 col-md-8 text-center text-lg-right text-md-right">
              <div className="header-top-info">
                <a href="mailto:info@eprimesoftsolutions.com">
                  <i className="fa fa-envelope mr-2"></i>
                  <span>info@eprimesoftsolutions.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg py-2" id="navbar">
        <div className="container">
          {/* Logo */}
          <Link
            className="navbar-brand"
            to="/"
            style={{ textDecoration: 'none' }}
            onMouseEnter={(e) => (e.currentTarget.firstChild.style.color = '#f74234')}
            onMouseLeave={(e) => (e.currentTarget.firstChild.style.color = 'white')}
          >
            <span style={{ color: 'white', transition: 'color 0.3s ease' }} className="prime-soft">
              Prime Soft
            </span>
            <span style={{ color: '#f74234' }}> Solutions.</span>
          </Link>

          {/* Navbar Toggler for Small Screens */}
          <button
            className={`navbar-toggler ${isNavOpen ? 'collapsed' : ''}`}
            type="button"
            onClick={toggleNavbar} // Handle the toggle on click
            aria-controls="navbarsExample09"
            aria-expanded={isNavOpen ? 'true' : 'false'} // Update aria-expanded based on state
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars"></span>
          </button>

          {/* Navbar Links (Collapsible) */}
          <div className={`collapse navbar-collapse text-center ${isNavOpen ? 'show' : ''}`} id="navbarsExample09">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/" onClick={handleLinkClick}>
                  Home <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about" onClick={handleLinkClick}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services" onClick={handleLinkClick}>
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/portfolio" onClick={handleLinkClick}>
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={handleLinkClick}>
                  Contact
                </Link>
              </li>
              {/* "Get a Quote" button */}
              <li className="nav-item">
                <Link
                  to="/contact"
                  className={`${styles.getQuoteButton} nav-link`}
                  onClick={handleLinkClick}
                >
                  Get a Quote
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
