import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  };

  const ctaItemStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    width: "100%",
    textAlign: "center",
    margin: "20px 0",
  };

  const titleStyle = {
    color: "#333",
    fontSize: "24px",
    lineHeight: "1.4",
    marginBottom: "16px",
  };

  const subtitleStyle = {
    color: "#f74234",
    fontSize: "18px",
    marginBottom: "12px",
  };

  const paragraphStyle = {
    color: "#555",
    fontSize: "16px",
    marginBottom: "16px",
  };

  const emailLinkStyle = {
    textDecoration: "none",
    color: "#222328",
    fontSize: "18px",
    transition: "color 0.3s ease",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const emailHoverStyle = {
    color: "#f77988",
  };

  return (
    <section style={containerStyle}>
      <div style={ctaItemStyle}>
        <span style={subtitleStyle}>We create for you</span>
        <h2 style={titleStyle}>
          Entrust Your Project to Our Best Team of Professionals
        </h2>
        <p style={paragraphStyle}>
          Have any project on mind? For immediate support:
        </p>
        <h4>
          <Link
            to="mailto:info@eprimesoftsolutions.com"
            style={emailLinkStyle}
            onMouseEnter={(e) =>
              (e.currentTarget.style.color = emailHoverStyle.color)
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.color = emailLinkStyle.color)
            }
          >
            <i className="fa fa-envelope" style={{ marginRight: "8px" }}></i>
            <span>info@eprimesoftsolutions.com</span>
          </Link>
        </h4>
      </div>
    </section>
  );
};

export default Cta;
